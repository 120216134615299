'use strict';
class XhrPromise {
    constructor() {
        let resolve_;
        let reject_;
        this.promise = new Promise((resolve, reject) => {
            resolve_ = resolve;
            reject_ = reject;
        });
        /** @private */
        this.resolve_ = resolve_;
        /** @private */
        this.reject_ = reject_;
        this.xhr = new XMLHttpRequest();
        this.xhr.withCredentials = true;
        this.xhr.onload = () => this.load_();
        this.xhr.onerror = () => this.error_();
        this.xhr.ontimeout = () => this.timeout_();
        /** @type {null|function(this:XhrPromise)} */
        this.beforeSend = null;
    }
    /**
     * @param {!string} url
     * @return {!Promise}
     */
    get(url) {
        return this.sendWithData('GET', url);
    }
    /**
     * @param {!string} url
     * @param {(string|Array|Object)=} data
     * @param {string=} contentType
     * @return {!Promise}
     */
    post(url, data = null, contentType = 'application/json') {
        return this.sendWithData('POST', url, data, contentType);
    }
    /**
     * @param {!string} url
     * @param {(string|Array|Object)=} data
     * @param {string=} contentType
     * @return {!Promise}
     */
    put(url, data = null, contentType = 'application/json') {
        return this.sendWithData('PUT', url, data, contentType);
    }
    /**
     * @param {!string} url
     * @param {(string|Array|Object)=} data
     * @param {string=} contentType
     * @return {!Promise}
     */
    delete(url, data = null, contentType = 'application/json') {
        return this.sendWithData('DELETE', url, data, contentType);
    }
    /**
     * @param {!string} method
     * @param {!string} url
     * @param {(string|Array|Object<string,*>)=} data
     * @param {string=} contentType
     * @return {!Promise}
     */
    sendWithData(method, url, data = null, contentType = 'application/json') {
        this.xhr.open(method, url, true);
        // in IE, we have to set the xhr.timeout AFTER calling open() or it throws an InvalidStateError.
        this.xhr.timeout = 30000;
        if (contentType) {
            this.xhr.setRequestHeader('content-type', contentType);
        }
        if (this.beforeSend) {
            this.beforeSend();
        }
        if (data && !(data instanceof String)) {
            if (!(data instanceof FormData) && typeof data !== 'string') {
                data = JSON.stringify(data);
            }
            else if (data === null) {
                data = undefined;
            }
        }
        this.xhr.send(data);
        return this.promise;
    }
    /** @private */
    load_() {
        const binaryTypes = ['arraybuffer', 'blob', 'document', 'json'];
        let response = binaryTypes.includes(this.xhr.responseType) ? this.xhr.response : this.xhr.responseText;
        if (this.JSON_RESPONSE_EXPR_.test(this.xhr.getResponseHeader('Content-Type'))) {
            if (response.length === 0) {
                response = {};
            }
            else {
                try {
                    response = JSON.parse(response);
                }
                catch (e) {
                    this.reject_(new Error(`JSON parsing exception: ${XhrPromise.ErrorCode.JSON_PARSE}`));
                    return;
                }
            }
        }
        if (this.xhr.status < 200 || this.xhr.status >= 300) {
            const err = new Error(`HTTP error status: ${this.xhr.status}`);
            err.statusCode = this.xhr.status;
            err['url'] = this.xhr.responseURL;
            err.response = response;
            this.reject_(err);
            return;
        }
        if (this.xhr.withCredentials) {
            XhrPromise.lastResponse_ = Date.now();
        }
        this.resolve_(response);
    }
    /** @private */
    timeout_() {
        const err = new Error(`Request timed out: ${XhrPromise.ErrorCode.TIMEOUT}`);
        err.errorCode = XhrPromise.ErrorCode.TIMEOUT;
        err['url'] = this.xhr.responseURL;
        this.reject_(err);
    }
    /** @private */
    error_() {
        const err = new Error(`Unknown network error: ${XhrPromise.ErrorCode.NETWORK_ERROR}`);
        err.errorCode = XhrPromise.ErrorCode.NETWORK_ERROR;
        err['url'] = this.xhr.responseURL;
        this.reject_(err);
    }
}
/**
 * @type {RegExp}
 * @private
 * @const
 */
XhrPromise.prototype.JSON_RESPONSE_EXPR_ = /^application\/json(;|$)/;
/** @enum {number} */
XhrPromise.ErrorCode = {
    TIMEOUT: -2,
    NETWORK_ERROR: -3,
    JSON_PARSE: -1
};
/** @type {!number} */
XhrPromise.lastResponse_ = 0;
// XhrPromise adds extra properties to the Error object
// We can't extend the native error class because that screws up stack traces
/** @type {number|undefined} */
Error.prototype.statusCode;
/** @type {?} */
Error.prototype.response;
export default XhrPromise;
