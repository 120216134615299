import JhaFetch from '../services/jha-fetch.js';
import XhrPromise from '../services/xhr-promise.js';
import LoginApi from '../services/auth/login-api.js';
import UserAccessController from './user-access-controller.js';
import JhaSessionStorage from '../session-storage/session-storage.js';
'use strict';
const _sessionStorage = new JhaSessionStorage();
class AuthController {
  static login(email, password, deviceId) {
    const xhr = new XhrPromise();
    let xsrfCookie;
    function getCookie() {
      xsrfCookie = JhaFetch.getCookie('XSRF-TOKEN');
    }
    xsrfCookie = getCookie();
    let cookieObtained = xsrfCookie ? Promise.resolve() : UserAccessController.isLoggedIn().then(getCookie, getCookie);
    xhr.beforeSend = function () {
      this.xhr.setRequestHeader('x-xsrf-token', xsrfCookie);
    };
    return cookieObtained.then(() => xhr.post(LoginApi.sentryLogin().url, { email, password, deviceId }));
  }
  static xplogin(SAMLToken, deviceId) {
    const xhr = new XhrPromise();
    let xsrfCookie;
    function getCookie() {
      xsrfCookie = JhaFetch.getCookie('XSRF-TOKEN');
    }
    xsrfCookie = getCookie();
    let cookieObtained = xsrfCookie ? Promise.resolve() : UserAccessController.isLoggedIn().then(getCookie, getCookie);
    xhr.beforeSend = function () {
      this.xhr.setRequestHeader('x-xsrf-token', xsrfCookie);
    };
    return cookieObtained.then(() => xhr.post('/a/xperience/api/login', { SAMLToken, deviceId }));
  }
  static forgotPassword(email) {
    return JhaFetch.fetch({
      api: LoginApi.sentryPasswordReset(),
      body: { email },
      method: 'POST',
    });
  }
  static getSessionNoCatch(cache = true) {
    return JhaFetch.fetch({ api: LoginApi.getSession(), method: 'GET', ignoreCache: true })
        .then((session) => {
          session.user = Object.assign(session.user, {
            highRiskEnrolled: session.highRisk.enrolled,
            profileUrl: '/a/settings/users/' + session.user.userId
          });
          if (cache) {
            _sessionStorage.setItem('BANNO_INSTITUTION_LIST', session.institutions);
            _sessionStorage.setItem('BANNO_USER', session.user);
          }
          return session;
        });
  }
  static getSession(cache = true) {
    return JhaFetch.fetch({ api: LoginApi.getSession(), method: 'GET', ignoreCache: true })
        .then((session) => {
          session.user = Object.assign(session.user, {
            highRiskEnrolled: session.highRisk.enrolled,
            profileUrl: '/a/settings/users/' + session.user.userId
          });
          if (cache) {
            _sessionStorage.setItem('BANNO_INSTITUTION_LIST', session.institutions);
            _sessionStorage.setItem('BANNO_USER', session.user);
          }
          return session;
        })
        .catch(error => Promise.reject(error));
  }
  static getSessionExpiration() {
    return JhaFetch.fetch({
      api: LoginApi.getSessionExpiration(),
      method: 'GET',
      ignoreCache: true,
    });
  }
  static logout() {
    return JhaFetch.fetch({
      api: LoginApi.authzLogout(),
      method: 'POST'
    });
  }

  static getJhidProvider(email) {
    return JhaFetch.fetch({
      api: LoginApi.jhidProviders(),
      method: 'GET',
      urlArguments: {email},
    });
  }
}
export default AuthController;
