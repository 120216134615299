// These values are intended to be updated with deploys
// Key Value pair. The key is the browser name.
// The values are objects containing the major versions of the browser (int)
// and counter start date in string format - 'yyyy/mm/dd'
const browserCheckData = {
    'IE': {
        version: 11,
        startDate: '2013/10/16'
    },
    'Edge': {
        version: 114,
        startDate: '2023/06/01'
    },
    'Firefox': {
        version: 113,
        startDate: '2023/05/08'
    },
    'Chrome': {
        version: 114,
        startDate: '2023/05/29'
    },
    'Safari': {
        version: 16.5,
        startDate: '2023/05/17'
    },
    'Mobile Safari': {
        version: 16.5,
        startDate: '2023/05/17'
    },
    
};
export default browserCheckData;
