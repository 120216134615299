'use strict';
class AppsService {
    static get aliases() {
        return [
            ['Kernel', 'Marketing'],
            ['Grip', 'Mobile', 'People'],
            ['Sentinel', 'Monitor'],
            ['CMS', 'Content'],
            ['Sentry', 'Settings']
        ];
    }
    static get applicationIds() {
        return [
            "7d695b9f-ca82-4bfc-9095-0baf4f7f81df",
            "700b41c6-99ce-4454-aaf4-5e1bcef2552f",
            "0323934a-e6b8-4690-b3e5-607362ab02b8",
            "5eb3d721-9ed5-42f9-90ab-8b6002921e6d",
            "bafd7f60-7b59-11e6-92e6-a6ed82c4d10d",
            "008fe870-38fd-11e4-916c-0800200c9a66",
            "f0a5d27c-f5a1-4fff-a6d2-ab08fec74f98",
            "ca7005d7-5f44-4e12-96ef-d0125b60af67",
            "816fd881-de3c-4875-befa-97c574ccc9fb",
            "7ae92401-2480-4940-b967-604c9711150c",
            "3453aa91-8905-4c50-8613-5a71ebf7da1e",
            "f8da8636-04e0-4857-b97c-a3f36288d900" // Cms
        ];
    }
    static get allToken() {
        return '_ALL';
    }
    static get allPrivileges() {
        return ['READ', 'CREATE', 'UPDATE', 'DELETE', 'APPROVE', 'PUBLISH'];
    }
    // Returns an array of all aliases for the given app name.
    static getAliases(name) {
        return AppsService.aliases.reduce((previousFound, aliasesChecking) => {
            if (aliasesChecking.indexOf(name) >= 0) {
                return aliasesChecking;
            }
            return previousFound;
        }, []);
    }
    static _capitalize(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    static normalizeResources(applications, resources) {
        const result = (resources) ? resources.map((item) => {
            const app = AppsService.getApplication(applications, item.applicationId);
            return {
                id: item.resource,
                applicationId: item.applicationId,
                name: item.resource === AppsService.allToken ?
                    AppsService._capitalize(app.name) :
                    AppsService._capitalize(item.resource),
                privileges: AppsService.normalizePrivileges(item.privileges)
            };
        }) : [];
        return result;
    }
    static normalizePrivileges(privileges) {
        let result = (privileges) ? privileges : AppsService.allPrivileges;
        // Make sure the privileges are an array.
        if (typeof result === 'string') {
            result = [result];
        }
        // Convert _ALL to the actual list of privileges.
        if (result.indexOf(AppsService.allToken) > -1) {
            Array.prototype.push.apply(result, AppsService.allPrivileges);
            result = result.filter(val => val !== AppsService.allToken);
        }
        return result.filter((privilege, index, self) => index === self.indexOf(privilege));
    }
    static normalizeApplications(applications, resources) {
        const normalizedResources = AppsService.normalizeResources(applications, resources);
        const normalizedApplications = (applications) ? applications.map((app) => {
            app.aliases = AppsService.getAliases(app.name);
            app.resources = normalizedResources.filter((resource) => app.applicationId === resource.applicationId);
            return app;
        }) : [];
        return normalizedApplications;
    }
    static normalizeUserApplications(platformApplications, userApplications) {
        const appsObject = {};
        userApplications.forEach((appInfo) => {
            const matchingApp = AppsService.getApplication(platformApplications, appInfo.applicationId);
            if (matchingApp) {
                const allAppNames = [matchingApp.name].concat(matchingApp.aliases || []);
                allAppNames.forEach((val) => {
                    appsObject[val.toLowerCase()] = appInfo;
                });
            }
        });
        return appsObject;
    }
    static getApplication(applications, idOrName) {
        idOrName = String(idOrName); // coerce to string
        const matches = applications.filter((val) => {
            const aliases = val.aliases || [];
            const lcAliases = aliases.map(name => name.toLowerCase());
            return val.applicationId === idOrName ||
                val.name.toLowerCase() === idOrName.toLowerCase() ||
                lcAliases.indexOf(idOrName.toLowerCase()) > -1;
        });
        if (matches && matches.length > 0) {
            return matches[0];
        }
        return null;
    }
    static normalizeUser(applications, user) {
        const sentryApp = AppsService.getApplication(applications, 'settings');
        const sentryUrl = (sentryApp) ? sentryApp.url : '';
        if (sentryUrl && user.userId) {
            user.profileUrl = `${sentryUrl}/users/${user.userId}`;
        }
        return user;
    }
    static _checkPermissionKey(permission, criteria, keyName) {
        // If this key was not specified in the criteria, there is nothing to match.
        // Resource IDs must explicitly match.
        if (!criteria[keyName] && keyName !== 'resourceId') {
            return true;
        }
        // Check if the value matches the criteria.
        if (keyName === 'privilege') {
            if (permission.privileges.indexOf(criteria[keyName]) !== -1) {
                return true;
            }
        }
        else if (criteria[keyName] === permission[keyName]) {
            return true;
        }
        // Check if the value is "_ALL".
        if (keyName === 'privilege') {
            if (permission.privileges.length === 1 && permission.privileges[0] === '_ALL') {
                return true;
            }
        }
        else if (permission[keyName] === '_ALL') {
            return true;
        }
        return false;
    }
    static hasPermission(permissions, privilege) {
        const checkPrivilege = Object.assign({}, privilege);
        // Remove any "_ALL" criteria.
        for (let key in checkPrivilege) {
            if (checkPrivilege.hasOwnProperty(key) && checkPrivilege[key] === '_ALL') {
                checkPrivilege[key] = undefined;
            }
        }
        // Filter out matching privileges.
        const matches = permissions.filter((permission) => {
            if (!AppsService._checkPermissionKey(permission, checkPrivilege, 'applicationId')) {
                return false;
            }
            if (!AppsService._checkPermissionKey(permission, checkPrivilege, 'resource')) {
                return false;
            }
            if (!AppsService._checkPermissionKey(permission, checkPrivilege, 'resourceId')) {
                return false;
            }
            if (!AppsService._checkPermissionKey(permission, checkPrivilege, 'privilege')) {
                return false;
            }
            return true;
        });
        return matches && matches.length > 0;
    }
}
export default AppsService;
