const cacheTime = 10000;
var _cache = {};
class JhaFetch {
  /*
      config {
        environmentUrl: String
        api: {
          url: String or Function,
          arguments: Array,
          requestMethods: Array
        }
        method: String
        urlArguments: {}
        header: {}
        body: {},
        sendBodyAsIs: Boolean,
        includeSuccessStatus: Boolean
      }*/
  static fetch(config) {
    return JhaFetch.buildFetch(config)
      .then(JhaFetch.executeFetch)
      .catch((err) => Promise.reject(err));
  }
  static fetchNoCatch(config) {
    return JhaFetch.buildFetch(config).then(JhaFetch.executeFetch);
  }
  static buildFetch(config) {
    config.options = {};
    config.options.headers = {};
    config.url = config.api.url;
    if (config.api.arguments && config.api.arguments.length > 0) {
      const args = config.api.arguments.map(
        (value) => config.urlArguments[value]
      );
      config.url = config.api.url.apply(this, args);
    }
    if (config.headers) {
      config.options.headers = config.headers;
    }
    if (config.sendBodyAsIs) {
      config.options.body = config.body;
    }
    if (config.body && !config.sendBodyAsIs) {
      config.options.body = JSON.stringify(config.body);
      config.options.headers = config.headers || {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      };
    }
    const xsrfCookie = JhaFetch.getCookie("XSRF-TOKEN");
    config.options.credentials = "same-origin";
    if (xsrfCookie) {
      config.options.headers["x-xsrf-token"] = xsrfCookie;
      config.options.credentials = "include";
    }
    config.options.method = config.method || "GET";
    config.method = config.method || "GET";
    config.ignoreCache = config.ignoreCache || false;
    config.includeSuccessStatus = config.includeSuccessStatus || false;

    return Promise.resolve(config);
  }
  static executeFetch(config) {
    const okStatus = 200;
    const createdStatus = 201;
    const acceptedStatus = 202;
    const noContentStatus = 204;
    const shouldCache = config.method === "GET";
    if (shouldCache && !config.ignoreCache) {
      if (_cache.hasOwnProperty(config.url)) {
        return _cache[config.url];
      }
    }
    /* if we're altering a collection, clear its `GET` from the cache */
    if (
      config.method === "PUT" ||
      config.method === "POST" ||
      config.method === "DELETE"
    ) {
      if (_cache.hasOwnProperty(config.url)) {
        delete _cache[config.url];
      }
    }
    const clearCallFromCache = () => {
      setTimeout(() => {
        delete _cache[config.url];
      }, cacheTime);
    };
    const promise = fetch(config.url, config.options)
      .then((response) => {
        if (shouldCache) {
          clearCallFromCache();
        }
        if (
          response.status !== acceptedStatus &&
          response.status !== createdStatus &&
          response.status !== noContentStatus &&
          response.status !== okStatus
        ) {
          let errorObj = {
            status: response.status,
            body: undefined,
          };
          var hasJsonBody = false;
          return response
            .clone()
            .json()
            .then((jsonErrorBody) => {
              hasJsonBody = true;
              errorObj.body = jsonErrorBody;
              return Promise.reject(errorObj);
            })
            .catch(function () {
              if (hasJsonBody) {
                return Promise.reject(errorObj);
              }
              return response.text().then((errorText) => {
                errorObj.body = errorText;
                return Promise.reject(errorObj);
              });
            });
        }
        if (config.options.method === "DELETE") {
          return Promise.resolve(response);
        }
        if (config.includeSuccessStatus) {
          return {
            status: response.status,
            body: response
              .clone()
              .json()
              .catch(function () {
                return response.text();
              }),
          };
        }
        /* return parsed JSON if response is valid JSON, else return text */
        return response
          .clone()
          .json()
          .catch(function () {
            return response.text();
          });
      })
      .catch((err) => {
        if (shouldCache) {
          clearCallFromCache();
        }
        return Promise.reject(err);
      });
    if (shouldCache) {
      _cache[config.url] = promise;
    }
    return promise;
  }
  static getCookie(name) {
    const partsLength = 2;
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === partsLength) {
      return parts.pop().split(";").shift();
    }
  }
  static _getEncodedParams(urlParams) {
    /* preserve Angular's behavior of excluding query params with
          undefined and null values */
    const validUrlParams = Object.entries(urlParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    return new URLSearchParams(validUrlParams).toString();
  }
  static getWithHeaders(uri, urlParams, headers, ignoreCache = false) {
    const config = {
      api: {
        url: urlParams ? `${uri}?${this._getEncodedParams(urlParams)}` : uri,
      },
      method: "GET",
      headers: headers,
      ignoreCache,
    };
    return JhaFetch.fetch(config);
  }
  static get(uri, urlParams, ignoreCache = false) {
    const config = {
      api: {
        url: urlParams ? `${uri}?${this._getEncodedParams(urlParams)}` : uri,
      },
      method: "GET",
      ignoreCache,
    };
    return JhaFetch.fetch(config);
  }
  static delete(uri) {
    const config = {
      api: {
        url: uri,
      },
      method: "DELETE",
    };
    return JhaFetch.fetch(config);
  }
  static post(uri, body) {
    const config = {
      api: {
        url: uri,
      },
      body: body,
      sendBodyAsIs: false,
      method: "POST",
    };
    return JhaFetch.fetch(config);
  }
  static put(uri, body) {
    const config = {
      api: {
        url: uri,
      },
      body: body,
      sendBodyAsIs: false,
      method: "PUT",
    };
    return JhaFetch.fetch(config);
  }
}
export default JhaFetch;
