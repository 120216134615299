import ApiPaths from '../api-paths.js';
'use strict';
class InstitutionsApi {
  static abilities() {
    return {
      url: function (institutionShortId) {
        return `${ApiPaths.MOBILE_PATH}/v0/enterprise/institutions/${institutionShortId}/abilities`;
      },
      arguments: ['institutionShortId'],
      requestMethods: ['GET']
    };
  }
  static abilitiesV1() {
    return {
      url: function(institutionId) {
        return `${ApiPaths.MOBILE_PATH}/v1/abilities/institutions/${institutionId}`;
      },
      arguments: ['institutionId'],
      requestMethods: ['GET']
    };
  }
  static consumerInstitutiondetails() {
    return {
      url: function (institutionId) {
        return `${ApiPaths.CONSUMER_PATH}/institutions/${institutionId}`;
      },
      arguments: ['institutionId'],
      requestMethods: ['GET']
    };
  }
  static consumerInstitutionIcon() {
    return {
      url: institutionId => `${ApiPaths.CONSUMER_PATH}/v0/institutions/${institutionId}/themes/default/light/icon`,
      arguments: ['institutionId'],
      requestMethods: ['GET']
    };
  }
  static sentryUsersInstitutions() {
    return {
      url: function (userId) {
        return `${ApiPaths.SENTRY_PATH}/users/${userId}/institutions`;
      },
      arguments: ['userId'],
      requestMethods: ['GET']
    };
  }
  static userInstitutions() {
    return {
      url: `${ApiPaths.AUTH_PATH}/session`,
      requestMethods: ['GET', 'PUT', 'DELETE']
    };
  }
  static userSelectedInstitutions() {
    return {
      url: `${ApiPaths.AUTH_PATH}/session/institution`,
      requestMethods: ['PUT']
    };
    // return {
    //   url: function(userId) {
    //     return `${ApiPaths.SENTRY_PATH}/users/${userId}/selected-institutions`;
    //   },
    //   arguments: ['userId'],
    //   requestMethods: ['PUT', 'DELETE']
    // };
  }
  static webServerConfig() {
    return {
      url: institutionId => `${ApiPaths.BASE_PATH}/institutions/${institutionId}/web-server-config`,
      requestMethods: ['GET'],
      arguments: ['institutionId']
    };
  }
}
export default InstitutionsApi;
