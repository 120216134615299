import RouteTreeNode from "21";
import RouteData from "20";
import page from "19";
const Context = page.Context;

/** @interface */
const BasicRoutingInterface = class {
  /**
   * Default implementation for the callback on entering a route node.
   * This will only be used if an element does not define it's own routeEnter method.
   *
   * @param {!RouteTreeNode} currentNode
   * @param {!RouteTreeNode|undefined} nextNodeIfExists
   * @param {string} routeId
   * @param {!Context} context
   * @param {function(boolean=)} next
   */
  routeEnter(currentNode, nextNodeIfExists, routeId, context, next) { }

  /**
   * Default implementation for the callback on exiting a route node.
   * This will only be used if an element does not define it's own routeExit method.
   *
   * @param {!RouteTreeNode} currentNode
   * @param {!RouteTreeNode|undefined} nextNode
   * @param {string} routeId
   * @param {!Context} context
   * @param {function(boolean=)} next
   */
  routeExit(currentNode, nextNode, routeId, context, next) { }
};

/**
 * @param {function(new:HTMLElement)} Superclass
 * @polymer
 * @mixinFunction
 */
function routingMixin(Superclass) {
  /**
   * @polymer
   * @mixinClass
   * @implements {BasicRoutingInterface}
   */
  class BasicRouting extends Superclass {
    /**
     * Default implementation for the callback on entering a route node.
     * This will only be used if an element does not define it's own routeEnter method.
     *
     * @param {!RouteTreeNode} currentNode
     * @param {!RouteTreeNode|undefined} nextNodeIfExists
     * @param {string} routeId
     * @param {!Context} context
     * @param {function(boolean=)} next
     */
    routeEnter(currentNode, nextNodeIfExists, routeId, context, next) {
      context.handled = true;
      const currentElement = /** @type {!Element} */ (currentNode.getValue().element);

      let elementDefined = Promise.resolve();
      if (nextNodeIfExists) {
        const nextNode = /** @type {!RouteTreeNode} */ (nextNodeIfExists);

        const nextNodeData = /** @type {!RouteData} */(nextNode.getValue());

        const thisElem = /** @type {!Element} */ (/** @type {?} */ (this));
        const nextElement = nextNodeData.element || thisElem.querySelector(nextNodeData.tagName.toLowerCase());

        // Reuse the element if it already exists in the dom.
        // Add a sanity check to make sure the element parent is what we expect
        /** @type {!Promise<!Element>} */
        let elementCreated;

        if (!nextElement || nextElement.parentNode !== currentElement) {
          if (nextNodeData.tagName.indexOf('-') > 0) {
            let Elem = customElements && customElements.get(nextNodeData.tagName.toLowerCase());
            if (Elem) {
              elementCreated = Promise.resolve(new Elem());
            } else {
              // When code splitting, it's possible that the element created is not yet in the registry.
              // Wait until it is before creating it
              elementCreated = customElements.whenDefined(nextNodeData.tagName.toLowerCase()).then(() => {
                Elem = customElements.get(nextNodeData.tagName.toLowerCase());
                return new Elem();
              });
            }
          } else {
            elementCreated = Promise.resolve(document.createElement(nextNodeData.tagName));
          }
        } else {
          elementCreated = Promise.resolve(nextElement);
        }

        const setElementAttributes = (callCount, nextElement) => {
          try {
            // Set appropriate attributes on the element from the route params
            for (const key in nextNodeData.attributes) {
              if (key in context.params) {
                if (context.params[key] !== undefined) {
                  nextElement.setAttribute(nextNodeData.attributes[key], context.params[key]);
                } else {
                  nextElement.removeAttribute(nextNodeData.attributes[key]);
                }
              }
            }

            if (!nextElement.parentNode) {
              while (currentElement.firstChild) {
                currentElement.removeChild(currentElement.firstChild);
              }
              currentElement.appendChild(nextElement);
            }

            nextNode.getValue().element = /** @type {!Element} */ (nextElement);
          } catch (e) {
            // Internet Explorer can sometimes throw an exception when setting attributes immediately
            // after creating the element. Add a short delay and try again.
            if (/Trident/.test(navigator.userAgent) && callCount < 4) {
              return new Promise((resolve) => {
                setTimeout(resolve, 0);
              }).then(() => setElementAttributes(callCount + 1, nextElement));
            }
            throw e;
          }
        };
        elementDefined = elementCreated.then((nextElement) => setElementAttributes(1, nextElement));
      }

      elementDefined
          .catch((e) => {
          // Set timeout is used to prevent the promise from swallowing the error
            setTimeout(() => {
              throw e;
            }, 0);
          })
          .then(next);
    }

    /**
     * Default implementation for the callback on exiting a route node.
     * This will only be used if an element does not define it's own routeExit method.
     *
     * @param {!RouteTreeNode} currentNode
     * @param {!RouteTreeNode|undefined} nextNode
     * @param {string} routeId
     * @param {!Context} context
     * @param {function(boolean=)} next
     */
    routeExit(currentNode, nextNode, routeId, context, next) {
      const currentElement = currentNode.getValue().element;

      if (currentElement.parentNode) {
        currentElement.parentNode.removeChild(/** @type {!Element} */ (currentElement));
      }
      currentNode.getValue().element = undefined;

      next();
    }
  }

  return BasicRouting;
}

/** @const */
/** @const */
routingMixin.Type = BasicRoutingInterface;
export default routingMixin;
/** @const */
