export default (class JhaSessionStorage {
    getItem(key) {
        let result;
        let parsed = null;
        try {
            result = window.sessionStorage.getItem(key);
            parsed = JSON.parse(result);
            return parsed;
        }
        catch (e) {
            return result;
        }
    }
    setItem(key, value) {
        try {
            window.sessionStorage.setItem(key, (typeof value === 'string') ? (value) : JSON.stringify(value));
        }
        catch (e) { }
    }
    removeItem(key) {
        try {
            window.sessionStorage.removeItem(key);
        }
        catch (e) { }
    }
    clear() {
        try {
            window.sessionStorage.clear();
        }
        catch (e) { }
    }
});
