// These values are intended to be updated with deploys
// Key Value pair. The key is the browser name.
// The values are objects containing the major versions of the browser (int)
// and counter start date in string format - 'yyyy/mm/dd'

// Xperience users are hardcoded to a specific versions of browsers and need
// to have different min version expectations
const browserCheckData = {
    'IE': {
        version: 11,
        startDate: '2013/10/16'
    },
    'Chrome': {
        version: 63,
        startDate: '2022/07/27'
    }
};
export default browserCheckData;
