import JhaSessionStorage from "./session-storage.js";
import InstitutionsController from '../controllers/institutions-controller.js';
const _sessionStorage = new JhaSessionStorage();
const STORE_PROPERTIES = {
    user_: 'BANNO_USER',
    institutions_: 'BANNO_INSTITUTION_LIST',
    apps_: 'BANNO_APPS',
    peoplePermissions_: 'PEOPLE_PERMISSIONS',
    ssoAction_: 'SSO_ACTION',
    pinnedConversations_: 'PINNED_CONVERSATIONS',
    institutionConfig_: 'INSTITUTION_CONFIG',
};
class UserInstitutionStore {
    constructor() {
        Object.keys(STORE_PROPERTIES).forEach((key) => {
            this[key] = _sessionStorage.getItem(STORE_PROPERTIES[key]);
        });
    }
    get user() {
        return this.user_;
    }
    set user(value) {
        this.user_ = value;
        if (value) {
            _sessionStorage.setItem(STORE_PROPERTIES.user_, value);
        }
        else {
            _sessionStorage.removeItem(STORE_PROPERTIES.user_);
        }
    }
    get institutions() {
        return this.institutions_;
    }
    set institutions(value) {
        this.institutions_ = value;
        if (value) {
            _sessionStorage.setItem(STORE_PROPERTIES.institutions_, value);
        }
        else {
            _sessionStorage.removeItem(STORE_PROPERTIES.institutions_);
        }
    }
    get apps() {
        return this.apps_;
    }
    set apps(value) {
        this.apps_ = value;
        if (value) {
            _sessionStorage.setItem(STORE_PROPERTIES.apps_, value);
        }
        else {
            _sessionStorage.removeItem(STORE_PROPERTIES.apps_);
        }
    }
    get peoplePermissions() {
        return this.peoplePermissions_;
    }
    set peoplePermissions(value) {
        this.peoplePermissions_ = value;
        if (value) {
            _sessionStorage.setItem(STORE_PROPERTIES.peoplePermissions_, value);
        }
        else {
            _sessionStorage.removeItem(STORE_PROPERTIES.peoplePermissions_);
        }
    }
    get ssoAction() {
        return this.ssoAction_;
    }
    set ssoAction(value) {
        this.ssoAction_ = value;
        if (value) {
            _sessionStorage.setItem(STORE_PROPERTIES.ssoAction_, value);
        }
        else {
            _sessionStorage.removeItem(STORE_PROPERTIES.ssoAction_);
        }
    }
    get pinnedConversations() {
        return this.pinnedConversations_;
    }
    set pinnedConversations(value) {
        this.pinnedConversations_ = value;
        if (value) {
            _sessionStorage.setItem(STORE_PROPERTIES.pinnedConversations_, value);
        }
        else {
            _sessionStorage.removeItem(STORE_PROPERTIES.pinnedConversations_);
        }
    }
    getSupportRecentSearches() {
        this.selectedInstitution = this.getSelectedInstitution();
        this.selectedInstitutionShortId = this.selectedInstitution.institution.shortId;
        this.supportRecentSearches_ = _sessionStorage.getItem(`${this.selectedInstitutionShortId}:supportRecentSearches`);
        return this.supportRecentSearches_;
    }
    set supportRecentSearches(value) {
        this.supportRecentSearches_ = value;
        if (value) {
            _sessionStorage.setItem(`${this.selectedInstitutionShortId}:supportRecentSearches`, value);
        }
        else {
            _sessionStorage.removeItem(`${this.selectedInstitutionShortId}:supportRecentSearches`);
        }
    }
    getSelectedInstitution() {
        if (!this.selectedInstitution_) {
            if (!this.institutions_) {
                return null;
            }
            this.selectedInstitution_ = this.institutions_.find(institutionObj => institutionObj.selected === true);
        }
        return this.selectedInstitution_;
    }
    set selectedInstitution(value) {
        if (!this.institutions) {
            return;
        }
        const newShortId = parseInt(value);
        const lastSelectedInstitution = this.institutions.find(institutionObj => institutionObj.selected === true);
        if (lastSelectedInstitution) {
            lastSelectedInstitution.selected = false;
        }
        const newSelectedInstitution = this.institutions.find(institutionObj => institutionObj.institution.shortId === newShortId);
        if (newSelectedInstitution) {
            newSelectedInstitution.selected = true;
            this.selectedInstitution_ = newSelectedInstitution;
        }
        this.setInstitutionApps(newSelectedInstitution.institution.institutionId);
        _sessionStorage.setItem(STORE_PROPERTIES.institutions_, this.institutions);
        document.dispatchEvent(new CustomEvent('institution-changed', {
            bubbles: true,
            composed: true,
            detail: { selectedInstitution: newSelectedInstitution },
        }));
    }
    async setInstitutionApps(institutionId) {
        if ((!this.apps || !this.apps[institutionId]) && InstitutionsController.addUserInstitution) {
            const storedApplications = {};
            const applicationsObj = await InstitutionsController.addUserInstitution(this.user, institutionId);
            storedApplications[institutionId] = applicationsObj.applications;
            this.apps = storedApplications;
        }
    }
    getAppsList(institutionId) {
        return (this.apps || {})[institutionId];
    }
    get institutionConfig() {
        return this.institutionConfig_;
    }
    set institutionConfig(value) {
        this.institutionConfig_ = value;
        if (value) {
            _sessionStorage.setItem(STORE_PROPERTIES.institutionConfig_, value);
        }
        else {
            _sessionStorage.removeItem(STORE_PROPERTIES.institutionConfig_);
        }
    }
    addAppsList(institutionId, appsList) {
        let bannoApps = this.apps || {};
        bannoApps[institutionId] = appsList;
        this.apps = bannoApps;
    }
}
const store = new UserInstitutionStore();
export default store;
