/* eslint-disable no-var */
/**
 * @fileoverview
 * From MDN.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
 *
 * Modified to account for elements in ShadowDom
 */

Element.prototype.closest = function(selector) {
  var matches = Array.prototype.slice.call((this.document || this.ownerDocument).querySelectorAll(selector));
  var el = this;
  while (el) {
    // If the element is directly inside a shadow root, it's parent is a document fragment with a host property.
    // In this case, query the shadow root for matches and add them to the list.
    if (!el.parentElement && el.parentNode && el.parentNode.host) {
      matches = matches.concat(Array.prototype.slice.call(el.parentNode.querySelectorAll(selector)));
      el = el.parentNode.host;
    } else {
      el = el.parentNode;
    }
  }

  el = this;
  for (; el; el = el.parentElement || (el.parentNode && el.parentNode.host)) {
    if (el !== this && el.shadowRoot) {
      matches = matches.concat(Array.prototype.slice.call(el.shadowRoot.querySelectorAll(selector)));
    }
    for (var i = matches.length - 1; i >= 0; i--) {
      if (el === matches[i]) {
        return el;
      }
    }
  }
  return el;
};