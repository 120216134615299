import '../../../shared/polyfills/element-closest';
import 'whatwg-fetch';
import '@webcomponents/shadycss/entrypoints/apply-shim.js';
import '@webcomponents/shadycss/entrypoints/custom-style-interface.js';
import '../components/dashboard-app.js';
import dialogPolyfill from 'dialog-polyfill';
window.dialogPolyfill = dialogPolyfill;
const styles = /** @type {!Array<!HTMLStyleElement>} */ ([...document.getElementsByTagName('style')]);
for (const style of styles) {
  if (style.hasAttribute('custom-style') && style.getAttribute('custom-style') !== 'applied') {
    window.ShadyCSS.CustomStyleInterface.addCustomStyle(style);
    style.setAttribute('custom-style', 'applied');
  }
}
