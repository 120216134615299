/**
 * @fileoverview Dependency enum used to create a routing tree.
 * All components which register a route must have a unique entry.
 */

/** @enum {string} tag name mapping */
const RouteId = {
  DASHBOARD_APP: 'A',
  LOGIN: 'B',
  LOGIN_LOGOUT: 'C',
  FORGOT_PASSWORD: 'D',
  INSTITUTIONS: 'E',
  DASHBOARD: 'F',
  BROWSER_UPGRADE: 'G',
  SET_PASSWORD: 'H',
  LOGIN_XPERIENCE: 'I',
  EULA: 'J',
};

export default RouteId;
