import JhaFetch from '../services/jha-fetch.js';
import XhrPromise from '../services/xhr-promise.js';
import LoginApi from '../services/auth/login-api.js';
import AppsService from '../services/apps/apps-service.js';
'use strict';
class UserAccessController {
  static isLoggedIn() {
    const xhr = new XhrPromise();
    const xsrfCookie = JhaFetch.getCookie('XSRF-TOKEN');
    if (xsrfCookie) {
      xhr.beforeSend = function () {
        this.xhr.setRequestHeader('x-xsrf-token', xsrfCookie);
      };
    }
    return xhr.get(LoginApi.sentryLogin().url);
  }
  static getSession() {
    return JhaFetch.fetch({ api: LoginApi.getSession(), method: 'GET', ignoreCache: true })
        .catch(error => Promise.reject(error));
  }
  static addUserInstitution(institutionId) {
    return JhaFetch.fetch({
      api: LoginApi.setInstitutionId(),
      method: 'PUT',
      body: { institutionId }
    })
        .catch(error => Promise.reject(error));
  }
  static getPermissions(institutionId) {
    return JhaFetch.fetch({ api: LoginApi.getPermissions(), method: 'GET', urlArguments: { institutionId } })
        .catch(error => Promise.reject(error));
  }
  static getUserPermissions(institutionId) {
    const body = UserAccessController.buildPermissionRequestBody(institutionId);
    return UserAccessController.validatePermissions(body)
        .then((result) => {
          return UserAccessController.transformPermissionResponse(result, body);
        })
        .catch(error => Promise.reject(error));
  }
  static validatePermissions(body) {
    return JhaFetch.fetch({
      api: LoginApi.userPermissions(),
      method: 'POST',
      body
    })
        .catch(error => Promise.reject(error));
  }
  static logout() {
    function redirect() {
      window.location.href = '/a/login';
    }
    const xsrfCookie = JhaFetch.getCookie('XSRF-TOKEN');
    if (xsrfCookie) {
      xhr.beforeSend = function () {
        this.xhr.setRequestHeader('x-xsrf-token', xsrfCookie);
      };
    }
    return xhr.delete(LoginApi.sentryLogin().url).then(redirect, redirect);
  }
  static transformPermissionResponse(response, body) {
    const allowed = response.filter((m) => m.result === 'allowed');
    const mapped = [];
    allowed.forEach((item) => {
      const found = body.find((n) => n.key === item.key);
      let existing = mapped.find((n) => {
        return n.applicationId === found.application && n.resource === found.resource;
      });
      if (existing == null) {
        existing = {
          applicationId: found.application,
          resource: found.resource,
          privileges: []
        };
        mapped.push(existing);
      }
      existing.privileges.push(found.privilege);
    });
    return mapped.map((item) => {
      if (item.resource === '+' || item.resource === '*') {
        item.resource = '_ALL';
      }
      return item;
    });
  }
  static buildPermissionRequestBody(institutionId) {
    const apps = AppsService.applicationIds;
    const allPrivileges = AppsService.allPrivileges;
    let key = 0;
    const body = [];
    apps.forEach((applicationId) => {
      allPrivileges.forEach((privilege) => {
        const item = {
          institutionId,
          privilege,
          application: applicationId,
          resource: '+',
          resourceId: '+',
          property: '+',
          key: (++key).toString() //`${ applicationId }:+:+:+:${ privilege }` // application:resource:resourceId:property:privilege
        };
        body.push(item);
      });
    });
    return body;
  }
  static hasPermission(permissionSet, checkFor) {
    return AppsService.hasPermission(permissionSet, checkFor);
  }
}
export default UserAccessController;
