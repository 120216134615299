/*
* This component is to deal with a webpack flaw where logging in and
* selecting an institution leads to a blank screen. Hopefully, by
* importing this nothing component first, the error will fire on it
* and not the navigation component, which is currently happening on the
* user-avatar-dropdown. This should be removed once webpack is removed
* from Dashboard's build
*/
import {LitElement, html, css} from "47";
const styles = css`
  :host {
    display: block;
  }
`;
/** @polymer */
class DashboardFiller extends LitElement {
  static get styles() {
    return [styles];
  }
  render() {
    return html``;
  }
}
customElements.define('dashboard-filler', DashboardFiller); // eslint-disable-line @banno/ux/custom-elements-define
export default DashboardFiller;
