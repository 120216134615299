
/**
 * @fileoverview
 *
 * Route tree definition for Assets.
 *
 *          __________________________DASHBOARD_APP____________________________________
 *         /              /                 |                      \                   \
 *  LOGIN-CONTAINER LOGIN-LOGOUT  FORGOT-PASSOWORD-CONTAINER  INSTITUTIONS-CONTAINER  APPS-CONTAINER
 */

import RoutePaths from './paths';
import RouteId from './id';
import RouteData from '@banno/banno-web/src/js/router/lib/route-data.js';
import RouteTreeNode from '@banno/banno-web/src/js/router/lib/route-tree-node.js';

const login = new RouteTreeNode(
    new RouteData(RouteId.LOGIN, 'LOGIN-CONTAINER', RoutePaths.LOGIN, [], false));

const loginLogout = new RouteTreeNode(
    new RouteData(RouteId.LOGIN_LOGOUT, 'LOGIN-LOGOUT', RoutePaths.LOGIN_LOGOUT, [], false));

const loginXperience = new RouteTreeNode(
    new RouteData(RouteId.LOGIN_XPERIENCE, 'XPERIENCE-TWO', RoutePaths.LOGIN_XPERIENCE, [], false));

const forgotPassword = new RouteTreeNode(
    new RouteData(RouteId.FORGOT_PASSWORD, 'FORGOT-PASSWORD-CONTAINER', RoutePaths.FORGOT_PASSWORD, [], false));

const setPassword = new RouteTreeNode(
    new RouteData(RouteId.SET_PASSWORD, 'SET-PASSWORD-CONTAINER', RoutePaths.SET_PASSWORD, [], false));

const browserUpgrade = new RouteTreeNode(
    new RouteData(RouteId.BROWSER_UPGRADE, 'BROWSER-UPGRADE', RoutePaths.BROWSER_UPGRADE, [], false));

const institutions = new RouteTreeNode(
    new RouteData(RouteId.INSTITUTIONS, 'INSTITUTIONS-CONTAINER', RoutePaths.INSTITUTIONS, [], true));

const dashboardContainer = new RouteTreeNode(
    new RouteData(RouteId.DASHBOARD, 'DASHBOARD-CONTAINER', RoutePaths.DASHBOARD, ['institutionShortId'], true));

const eula = new RouteTreeNode(
    new RouteData(RouteId.EULA, 'EULA-CONTAINER', RoutePaths.EULA, ['mode', 'institutionId'], false));

const dashboardApp = new RouteTreeNode(
    new RouteData(RouteId.DASHBOARD_APP, 'DASHBOARD-APP', RoutePaths.DASHBOARD_APP, [], false));

dashboardApp.addChild(login);
dashboardApp.addChild(loginLogout);
dashboardApp.addChild(loginXperience);
dashboardApp.addChild(forgotPassword);
dashboardApp.addChild(setPassword);
dashboardApp.addChild(browserUpgrade);
dashboardApp.addChild(institutions);
dashboardApp.addChild(dashboardContainer);
dashboardApp.addChild(eula);

export default dashboardApp;
