import {css} from 'lit-element';

const styles = css`
  @keyframes inDown {
    0% {
      transform: translate3d(0, -55px, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  :host {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    --application-color: #445565;
    --application-icon-margin: 6px 0 0 0;
    --jha-app-icon: #8c989f;
    --jha-date-picker-day-range: rgba(58, 174, 218, .14);
    --jha-date-range-toggle-text: rgba(255, 255, 255, .6);

    /* JHA Component Color Custom Variables */
    --jha-color-primary: #006ee4;
    --jha-color-success: #118315;
    --jha-color-danger: #DB2012;
    --jha-color-warning:#C98702;
    --jha-color-purple: #7D57C1;
    --jha-color-teal: #008091;
    --jha-color-dark: #191919;
    --jha-color-gray-medium: #707070;
    --jha-color-gray-light: #e6e6e6;
    --jha-color-gray-lighter: #f2f2f2;
    --jha-color-neutral: #8d99a0;
    --jha-color-muted: var(--jha-text-muted);
    --jha-text-dark: #191919;
    --jha-text-base: #707070;
    --jha-color-light: #8c989f;
    --jha-text-light: #8c989f;
    --jha-text-white: #ffffff;
    --jha-text-success: #118315;
    --jha-text-danger: #DB2012;
    --jha-text-warning: #BB5C04;
    --jha-text-theme: var(--jha-color-primary);
    --jha-text-neutral: #8d99a0;
    --jha-text-muted: #b0b0b0;
    --jha-background-color: #f8f8f8;
    --jha-component-background-color: #ffffff;
    --jha-focus-highlight-color: rgba(153,153,153,.12);
    --jha-text-size-base: 14px;
    --jha-border-color: #e4e7ea;
    --jha-border-color-hover: #d3d8dd;

    --jha-content-inset: 24px;

    --jha-accounts-card-balance-title-size: 13px;
    --jha-accounts-card-balance-title-color: var(--jha-text-light);
    --jha-accounts-card-balance-title-top-margin: 0;
    --jha-accounts-card-fi-info-size: 13px;
    --jha-accounts-card-fi-info-color: var(--jha-text-light);
    --jha-accounts-card-fi-info-top-margin: 0;
    --jha-accounts-card-title-size: 16px;
    --jha-accounts-card-title-color: var(--jha-text-dark);
    --jha-accounts-card-title-weight: 400;
    --jha-button-text-size: 14px;
    --jha-card-article-margin-left: 24px;
    --jha-card-article-padding-right: 24px;
    --jha-card-article-margin-bottom: 18px;
    --jha-card-article-padding-bottom: 18px;
    --jha-card-footer-size: 16px;
    --jha-card-footer-margin-left: 24px;
    --jha-card-footer-padding-top: 12px;
    --jha-card-footer-padding-right: 24px;
    --jha-card-footer-padding-bottom: 12px;
    --jha-card-header-margin-left: 24px;
    --jha-card-header-padding-top: 24px;
    --jha-card-header-padding-right: 24px;
    --jha-card-header-padding-bottom: 16px;
    --jha-card-header-display: flex;
    --jha-card-header-display-ie: flex;

    --jha-close-icon-color: var(--jha-color-muted);

    --jha-currency-color: var(--jha-text-dark);
    --jha-currency-size: 14px;

    --jha-date-picker-day-headers-text-transform: none;

    --jha-date-range-toggle-background: #dfe1e3;
    --jha-date-range-toggle-border: rgb(218, 220, 222);

    --jha-form-control-input-disabled-background: rgba(238,241,244, 0.7);

    --jha-form-floating-group-filled-background: var(--jha-background-color);
    --jha-form-floating-group-underline-background: var(--jha-border-color);

    --jha-icon-computer-device-fill: #3D454D;

    --jha-list-item-vertical-spacing: 16px;
    --jha-list-item-horizontal-spacing: 24px;

    --jha-dropdown-menu-item-display: flex;
    --jha-dropdown-menu-item-margin: 8px;
    --jha-dropdown-menu-item-background-hover: var(--jha-color-gray-light);

    --jha-pagination-dot-color: var(--jha-color-neutral);
    --jha-pagination-dot-color-selected: var(--jha-color-primary);

    --jha-progress-color: var(--jha-color-muted);
    --jha-progress-card-border-width: 2px;

    --jha-search-box-background: #edeeef;
    --jha-search-box-border: #e7e9ea;

    --jha-tab-item-color: var(--jha-text-base);
    --jha-tab-item-button-color: var(--jha-text-dark);
    --jha-tab-item-color-active: var(--jha-color-primary);
    --jha-tab-item-text-transform: capitalize;
    --jha-tab-item-padding: 0;

    --jha-well-background-color: #f5f6f6;

    --transaction-list-deposit-amount-color: var(--jha-color-success);

    /* Banno Online Component Variables */
    --account-card-background-primary-color:var(--jha-color-primary);
    --account-card-background-secondary-color:var(--jha-color-neutral);
    --account-card-primary-text-color:var(--jha-text-white);
    --account-card-secondary-text-color:var(--jha-text-white);
    --action-button-active-color:var(--jha-color-primary);
    --action-button-color:var(--jha-color-primary);
    --action-button-disabled-color:var(--jha-color-light);
    --action-button-focused-color:var(--jha-color-primary);
    --action-button-hover-color:var(--jha-color-primary);
    --action-button-pressed-color:var(--jha-color-primary);
    --action-button-text-color:var(--jha-text-white);
    --action-button-text-disabled-color:var(--jha-text-light);
    --action-button-text-focused-color:var(--jha-text-white);
    --action-button-text-pressed-color:var(--jha-text-white);
    --body-footer-color:var(--jha-color-neutral);
    --body-header-background-color:var(--application-color);
    --body-header-icon-color:var(--jha-text-white);
    --body-header-title-color:var(--jha-text-white);
    --body-text-alert-color:var(--jha-color-warning);
    --body-text-completed-color:var(--jha-color-success);
    --body-text-highlight-color:var(--jha-color-success);
    --body-text-primary-color:var(--jha-text-base);
    --body-text-secondary-color:var(--jha-text-light);
    --body-text-theme-color:var(--jha-text-theme);
    --card-title-text-color:var(--jha-text-light);
    --completed-color:var(--jha-color-success);
    --contact-button-color:var(--jha-color-light);
    --contact-button-disabled-color:var(--jha-color-light);
    --contact-button-focused-color:var(--jha-color-primary);
    --contact-button-icon-color:var(--jha-text-light);
    --contact-button-icon-disabled-color:var(--jha-focus-highlight-color);
    --contact-button-icon-focused-color:var(--jha-color-primary);
    --contact-button-icon-pressed-color:var(--jha-text-white);
    --contact-button-pressed-color:var(--jha-color-primary);
    --contact-button-text-color:var(--jha-text-base);
    --contact-button-text-disabled-color:var(--jha-focus-highlight-color);
    --contact-button-text-focused-color:var(--jha-color-primary);
    --contact-button-text-pressed-color:var(--jha-text-white);
    --controller-view-background-color:var(--jha-color-neutral);
    --dashboard-container-background-color:var(--jha-background-color);
    --deposit-amount-color:var(--jha-text-base);
    --divider-color:var(--jha-border-color);
    --error-background-color:var(--jha-color-warning);
    --error-text-color:var(--jha-text-white);
    --focus-highlight-color:var(--jha-focus-highlight-color);
    --indicator-background-color:var(--jha-color-warning);
    --indicator-text-color:var(--jha-text-white);
    --inline-button-color:var(--jha-text-white);
    --inline-button-disabled-color:var(--jha-text-white);
    --inline-button-focused-color:var(--jha-color-primary);
    --inline-button-pressed-color:var(--jha-color-primary);
    --inline-button-text-active-color:var(--jha-color-primary);
    --inline-button-text-color:var(--jha-text-theme);
    --inline-button-text-disabled-color:var(--jha-color-light);
    --inline-button-text-focused-color:var(--jha-color-primary);
    --inline-button-text-hover-color:var(--jha-color-primary);
    --inline-button-text-pressed-color:var(--jha-text-white);
    --inline-icon-background-color:var(--jha-color-primary);
    --inline-icon-background-disabled-color:var(--jha-color-light);
    --inline-icon-background-focused-color:var(--jha-color-primary);
    --inline-icon-background-pressed-color:var(--jha-color-primary);
    --inline-icon-button-color:var(--jha-color-primary);
    --inline-icon-button-disabled-color:var(--jha-text-light);
    --inline-icon-button-focused-color:var(--jha-color-primary);
    --inline-icon-button-pressed-color:var(--jha-color-primary);
    --inline-icon-color:var(--jha-text-white);
    --inline-icon-disabled-color:var(--jha-text-white);
    --inline-icon-focused-color:var(--jha-text-white);
    --inline-icon-pressed-color:var(--jha-text-white);
    --inline-icon-text-color:var(--jha-text-base);
    --inline-icon-text-disabled-color:var(--jha-color-light);
    --inline-icon-text-focused-color:var(--jha-color-primary);
    --inline-icon-text-pressed-color:var(--jha-text-white);
    --input-entered-text-color:var(--jha-text-theme);
    --input-placeholder-text-color:var(--jha-text-light);
    --list-divider-text-color:var(--jha-text-base);
    --login-container-background-color:var(--jha-component-background-color);
    --magnetic-ad-background-color:var(--jha-color-dark);
    --magnetic-ad-text-color:var(--jha-text-white);
    --menu-background-color:var(--jha-text-white);
    --menu-icon-background-color:var(--jha-text-white);
    --menu-icon-background-disabled-color:var(--jha-text-white);
    --menu-icon-background-focused-color:var(--jha-text-white);
    --menu-icon-background-pressed-color:var(--jha-text-white);
    --menu-icon-color:var(--jha-color-primary);
    --menu-icon-disabled-color:var(--jha-color-primary);
    --menu-icon-focused-color:var(--jha-color-primary);
    --menu-icon-pressed-color:var(--jha-color-primary);
    --menu-item-color:var(--jha-text-white);
    --menu-item-disabled-color:var(--jha-color-primary);
    --menu-item-divider-color:var(--jha-border-color);
    --menu-item-focused-color:var(--jha-color-primary);
    --menu-item-pressed-color:var(--jha-color-primary);
    --menu-text-color:var(--jha-text-base);
    --menu-text-disabled-color:var(--jha-text-light);
    --menu-text-focused-color:var(--jha-text-base);
    --menu-text-pressed-color:var(--jha-text-base);
    --navigation-bar-color:var(--jha-text-theme);
    --navigation-bar-icon-color:var(--jha-color-light);
    --navigation-bar-item-text-color:var(--jha-color-light);
    --navigation-bar-title-color:var(--jha-text-white);
    --pin-dot-color:var(--jha-color-primary);
    --primary-content-background-color:var(--jha-text-white);
    --primary-icon-background-color:var(--jha-color-primary);
    --primary-icon-color:var(--jha-text-white);
    --rejected-color:var(--jha-color-warning);
    --secondary-content-background-color:var(--jha-well-background-color);
    --secondary-content-background-hover-color:var(--jha-color-light);
    --secondary-icon-background-color:var(--jha-color-primary);
    --secondary-icon-color:var(--jha-text-white);
    --secondary-page-container-background-color:var(--jha-background-color);
    --status-bar-action-mode-color:var(--jha-text-theme);
    --status-bar-color:var(--jha-color-primary);
    --step-indicator-active-color:var(--jha-color-primary);
    --step-indicator-inactive-color:var(--jha-color-dark);
    --toggle-switch-active-color:var(--jha-color-success);
    --toggle-switch-inactive-color:var(--jha-text-white);
    --toolbar-action-mode-color:var(--jha-color-primary);
    --toolbar-action-mode-icon-color:var(--jha-text-white);
    --toolbar-action-mode-title-color:var(--jha-text-white);
    --toolbar-color:var(--jha-text-theme);
    --toolbar-focus-highlight-color:var(--jha-focus-highlight-color);
    --toolbar-icon-color:var(--jha-text-white);
    --toolbar-title-color:var(--jha-text-white);
    --transaction-list-deposit-amount-color:var(--jha-color-success);
    --transaction-list-withdrawal-amount-color:var(--jha-text-base);
    --withdrawal-amount-color:var(--jha-text-base);

    --jha-list-item-bottom-border: var(--jha-color-gray-light, #e6e6e6);

    /* base site styling */
    font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: var(--jha-text-size-base);
    line-height: 1.5;
    margin: 0px;
    color: var(--jha-text-base);
  }
  /* Default base HTML element styles */
  * {
    box-sizing: border-box;
  }
  h1,
  h2,
  h3,
  h4 {
    color: var(--jha-text-dark);
    font-weight: 500;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 21px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  button {
    touch-action: manipulation;
    background: none;
    border: none;
    color: var(--jha-text-dark);
    cursor: pointer;
    display: block;
    font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    margin: 0;
    padding: 0;
    text-align: left;
    -webkit-appearance: none;
  }
  a {
    text-decoration: none;
    color: var(--jha-color-primary);
    cursor: pointer;
  }
  a:hover,
  a:active {
    color: var(--jha-color-primary);
  }
  a:hover {
    text-decoration: underline;
  }
  a[active],
  a:active{
    background-color: rgba(1, 111, 230, 0.1);
    color: var(--jha-color-primary);
    font-weight: 500;
    border-radius: 6px;
  }
  a[active] svg,
  a:active svg {
    fill: var(--jha-color-primary);
  }
  svg {
    width: 24px;
    height: 24px;
    fill: #444444;
  }
  hr {
    box-sizing: content-box;
    height: 1px;
    background-color: var(--jha-border-color);
    border: 0;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  label {
    color: var(--jha-text-dark);
    font-size: 12px;
    font-weight: 500;
  }
  sup {
    font-size: 75%;
    position: relative;
    top: 1.5px;
  }
  /* Attribute-based styles */
  *[flex] {
    display: flex !important;
    align-items: center;
  }
  *[title] {
    color: var(--jha-text-dark);
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  *[sub-title] {
    color: var(--jha-text-base);
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
  }
  *[small] {
    font-size: 13px;
  }
  *[x-small] {
    font-size: 12px;
  }
  *[bold] {
    font-weight: 600;
  }
  *[semi-bold] {
    font-weight: 500;
  }
  sup {
    font-size: 75%;
    position: relative;
    top: 1.5px;
  }
`;

export {styles as jhaStyles};
