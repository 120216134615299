import browserCheckData from "./browser-data.js";
import xperienceBrowserCheckData from "./xperience-browser-data.js";
import UAParser from "ua-parser-js/dist/ua-parser.min";
const parser = new UAParser(navigator.userAgent);
const hoursPerDay = 24;
const minutesPerHour = 60;
const secondsPerMinute = 60;
const msPerSecond = 1000;
const oneDay = hoursPerDay * minutesPerHour * secondsPerMinute * msPerSecond;
const isXperienceUser = typeof CefSharp !== 'undefined' && CefSharp.BindObjectAsync;
const checkData = isXperienceUser ? xperienceBrowserCheckData : browserCheckData;
class BrowserCheckService {
    static getInfo() {
        let startDate = new Date().toDateString();
        const minimumSupportedBrowsers = {};
        const browser = parser.getBrowser();
        const browserCheckReturn = {
            browserAllowed: true,
            browserOutOfDate: false,
            daysRemaining: 0,
            clientBrowser: browser,
            supportedBrowser: checkData[browser.name]
        };
        if (browser.name === 'Brave') {
            // caniuse-db doesn't include Brave and suggests you treat it as Chrome
            browser.name = 'Chrome';
        }
        if (browser.name === 'IE') {
            browserCheckReturn.browserOutOfDate = true;
            return browserCheckReturn;
        }
        const isBrowserOutdated = () => {
            const allowedVersionsUntilOutdated = 2;
            if (!browser.name) {
                return true;
            }
            return Number(browser.major) < (Math.floor(minimumSupportedBrowsers[browser.name]) - allowedVersionsUntilOutdated);
        };
        const getDaysRemainingToUpgrade = () => {
            const numDaysToUpgrade = 60;
            const endDate = new Date(startDate);
            const today = new Date(new Date().toDateString());
            endDate.setDate(endDate.getDate() + numDaysToUpgrade);
            const remaining = Math.round((endDate - today) / (oneDay));
            return remaining <= 0 ? 0 : remaining;
        };
        const isBrowserAllowed = (toState) => {
            // Current version (1) + Allowed versions (2) + Outdated versions (2)
            const totalVersionsAllowed = 5;
            const stateName = toState ? toState.name : '';
            const allowedStates = /^(browser)$/;
            if (allowedStates.test(stateName)) {
                return true;
            }
            if (!browser.name || (browser.name === 'IE' && Number(browser.major) !== minimumSupportedBrowsers['IE'])) {
                return false;
            }
            if (!minimumSupportedBrowsers[browser.name]) {
              return true;
            }
            return Number(browser.major) > (minimumSupportedBrowsers[browser.name] - totalVersionsAllowed);
        };
        for (let prop in checkData) {
            if (checkData.hasOwnProperty(prop)) {
                minimumSupportedBrowsers[prop] = checkData[prop].version;
            }
        }
        if (checkData[browser.name] && checkData[browser.name].startDate) {
            startDate = checkData[browser.name].startDate;
        }
        if (!isBrowserAllowed()) {
            browserCheckReturn.browserAllowed = false;
            return browserCheckReturn;
        }
        if (isBrowserOutdated()) {
            browserCheckReturn.browserOutOfDate = true;
            browserCheckReturn.daysRemaining = getDaysRemainingToUpgrade();
            return browserCheckReturn;
        }
        return browserCheckReturn;
    }
}
export default BrowserCheckService;
