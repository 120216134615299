import JhaFetch from '../services/jha-fetch.js';
import InstitutionsApi from '../services/institutions/institutions-api.js';
import store from '../session-storage/store.js';
'use strict';
class InstitutionsController {
  static getInstitutionAbilities(institutionShortId) {
    return JhaFetch.fetch({ api: InstitutionsApi.abilities(), method: 'GET', urlArguments: { institutionShortId } })
        .catch(error => Promise.reject(error));
  }
  static getInstitutionAbilitiesV1(institutionId) {
    return JhaFetch.fetch({ api: InstitutionsApi.abilitiesV1(), method: 'GET', urlArguments: { institutionId } })
        .catch(error => Promise.reject(error));
  }
  static getInstitutionDetails(institutionId) {
    return JhaFetch.fetch({ api: InstitutionsApi.consumerInstitutiondetails(), method: 'GET', urlArguments: { institutionId } })
        .catch(error => Promise.reject(error));
  }
  /**
   * @param {string=} userId
   * @return {!Promise<{
   *   institutions: Array
   * }>}
   */
  static loadInstitutions(userId) {
    const institutions = store.institutions;
    if (institutions) {
      return Promise.resolve(institutions);
    }
    else if (userId) {
      return JhaFetch.fetch({ api: InstitutionsApi.userInstitutions(), urlArguments: { userId: userId }, ignoreCache: true })
          .then((userInstitutions) => {
          // Since we are making the session call anyway lets make sure we have the right user in the store.
            store.user = userInstitutions.user;
            store.institutions = userInstitutions.institutions;
            return userInstitutions.institutions;
          })
          .catch(error => Promise.reject(error));
    }
    else {
      return Promise.reject();
    }
  }
  static loadAnotherUsersInstitutions(userId) {
    if (userId) {
      return JhaFetch.fetch({ api: InstitutionsApi.sentryUsersInstitutions(), urlArguments: { userId: userId }, ignoreCache: true })
          .then((userInstitutions) => {
            return userInstitutions.institutions;
          })
          .catch(error => Promise.reject(error));
    }
    else {
      return Promise.reject();
    }
  }
  static removeUserInstitution(userId) {
    return JhaFetch.fetch({
      api: InstitutionsApi.userSelectedInstitutions(),
      urlArguments: { userId: userId },
      method: 'DELETE'
    })
        .catch(error => Promise.reject(error));
  }
  static addUserInstitution(userId, institutionId) {
    return JhaFetch.fetch({
      api: InstitutionsApi.userSelectedInstitutions(),
      method: 'PUT',
      body: { institutionId }
    }).catch(error => Promise.reject(error));
  }
  static getWebServerConfig(institutionId) {
    return JhaFetch.fetch({
      api: InstitutionsApi.webServerConfig(),
      method: 'GET',
      urlArguments: { institutionId }
    });
  }
  static getSelectedInstitution(institutions, shortId, longId) {
    institutions = institutions || store.institutions || [];
    const selectedInstitution = institutions.find(institution => {
      if (shortId) {
        return institution.institution.shortId === shortId;
      } else if (longId) {
        return institution.institution.institutionId === longId;
      } else {
        return institution.selected === true;
      }
    });
    return Promise.resolve(selectedInstitution || {});
  }
}
export default InstitutionsController;
